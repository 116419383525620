import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import { LoginPage } from './pages/Login';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import NewProductPage from './pages/NewProductPage';
import { FormV3 } from './pages/FormV3';
import { LandingPage } from './pages/LandingPage';
import { RegisterPage } from './pages/Register';
import { SelfFeedbackForm } from './pages/SelfFeedbackForm';
import UserProfilePage from './pages/UserProfile';
import TennisCourtPage from './pages/CourtInsights';
import CourtRadar from './pages/CourtRadar';
import NewTrainingSessionPage from './pages/NewTrainingSessionPage';
import JournalPage from './pages/JournalPage';
import CreateTournamentPage from './pages/CreateTournamentPage';
import TournamentRegistrationPage from './pages/TournamentRegistrationPage';
import TournamentListPage from './pages/TournamentListPage';
import JournalEntriesForUser from './pages/JournalEntriesForUser';
import TennisJournalingBlog from './pages/JournalBlogPost';
import TournamentPage from './pages/TournamentLandingPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <SimpleLayout />,
      children: [
        { path: '', element: <LandingPage /> },
      ],
    },
    {
      path: '/v2',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/v2/dashboard" />, index: true },
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'sessions', element: <UserPage /> },
        { path: 'sessions/create/competitive', element: <NewProductPage type="Competitive" /> },
        { path: 'sessions/create/training', element: <NewTrainingSessionPage type="Training" /> },
        { path: 'sessions/create/practice', element: <NewProductPage type="Practice" /> },
        { path: 'sessions/feedback/:matchId', element: <FormV3 /> },
        { path: 'feedback/self/new/:playId', element: <SelfFeedbackForm /> },
        { path: 'feedback/:playId', element: <SelfFeedbackForm /> },
        { path: 'profile/:userId', element: <UserProfilePage /> },
        { path: 'courts/:courtId', element: <TennisCourtPage /> },
        { path: 'courts', element: <CourtRadar /> },
        { path: 'tournaments', element: <TournamentListPage /> },
        { path: 'tournaments/create', element: <CreateTournamentPage /> },
        { path: 'tournaments/:tournamentId/register', element: <TournamentPage /> },
        { path: 'tournaments/:tournamentId', element: <TournamentPage /> },
        { path: 'blog/importance-of-journaling', element: <TennisJournalingBlog /> },
        { path: 'journal', element: <JournalEntriesForUser /> },
        { path: 'journal/create', element: <JournalPage /> },
        { path: 'journal/view/:journalId', element: <JournalPage /> }
      ],
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          path: 'register',
          element: <RegisterPage />,
        },
        {
          path: 'login',
          element: <LoginPage />,
        },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
