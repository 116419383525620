// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQCsisFkj3Y9LDWri3JY74Lyy_fZ7L48I",
  authDomain: "playgrade-31eff.firebaseapp.com",
  projectId: "playgrade-31eff",
  storageBucket: "playgrade-31eff.appspot.com",
  messagingSenderId: "525735518062",
  appId: "1:525735518062:web:2c30f069b1241cb8f32b7f",
  measurementId: "G-1YF828GVH2"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export default auth;