import React, { useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { AuthProvider } from './utils/auth';

// Initialize GA
ReactGA.initialize('G-1YF828GVH2');

// Custom hook for page tracking
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname + location.search });
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

// App component
function App() {
  usePageTracking();

  return (
    <HelmetProvider>
      <AuthProvider>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router />
        </ThemeProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}

// Wrapper component to provide routing context
export default function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}