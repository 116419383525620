import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Chip, List, ListItemText, ListItem, Typography } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, comingSoon=false } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      disabled={comingSoon}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItem>
      <ListItemText
        disableTypography
        primary={
          <Typography component="div" variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {title}
            {comingSoon && <Chip color="primary" size="small" label="Upcoming" sx={{ml:0.5}} />}
          </Typography>
        }
      />
    </ListItem>

      {info && info}
    </StyledNavItem>
  );
}
