import axios from "axios";

const BASE_URL_PROD = "https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production"

export const getPlayByPlayId = async (playId) => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/plays/${playId}`,
    headers: {
      "Content-Type": "text/plain",
    },
  }
  const result = await axios(config);
  return result
}

export const getFeedbackByFeedbackId = async (feedbackId) => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/feedbacks/${feedbackId}`,
    headers: {
      "Content-Type": "text/plain",
    },
  }
  const result = await axios(config);
  return result
}

export const getFeedbacksByPlayId = async (playId) => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/feedbacks/plays/${playId}`,
    headers: {
      "Content-Type": "text/plain",
    }
  }
  const result = await axios(config);
  return result
}

export const getUserIdFromEmail = async (email) => {
  const getUserIdConfig = {
    method: "get",
    url: `${BASE_URL_PROD}/users/email/${email}`,
    headers: {
      "Content-Type": "text/plain",
    },
  };
  const result = await axios(getUserIdConfig)
  const userId = result.data[0]?.id || ''
  if (!userId) {
    console.log(`User Id not found for email address ${email}`)
  }
  return userId
}

export const getUserIdFromPhoneNumber = async (phoneNumber) => {
  const getUserIdConfig = {
    method: "get",
    url: `${BASE_URL_PROD}/users/phoneNumber/${phoneNumber}`,
    headers: {
      "Content-Type": "text/plain",
    },
  };
  const result = await axios(getUserIdConfig)
  const userId = result.data[0]?.id || ''
  if (!userId) {
    console.log(`User Id not found for email address ${phoneNumber}`)
  }
  return userId
}

export const getUserByUserId = async (userId) => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/users/${userId}`,
    headers: {
      "Content-Type": "text/plain",
    },
  }
  const result = await axios(config);
  return result
}

export const getCourtInsightsById = async (courtId) => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/courts/insights/${courtId}`,
    headers: {
      "Content-Type": "text/plain",
    },
  }
  const result = await axios(config);
  return result
}

export const getCourtRadarCourts = async (courtId) => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/courts/insights`,
    headers: {
      "Content-Type": "text/plain",
    },
  }
  const result = await axios(config);
  return result
}

export const getTournamentById = async (tournamentId) => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/tournaments/${tournamentId}`,
    headers: {
      "Content-Type": "text/plain",
    },
  }
  const result = await axios(config);
  return result
}

export const getRegistrationsByTournamentId = async (tournamentId) => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/tournaments/${tournamentId}/registrations`,
    headers: {
      "Content-Type": "text/plain",
    },
  }
  const result = await axios(config);
  return result
}

export const getTournamentRegistrationsByUserId = async (userId) => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/tournaments/users/${userId}`,
    headers: {
      "Content-Type": "text/plain",
    },
  }
  const result = await axios(config);
  return result
}

export const getAllTournaments = async () => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/tournaments`,
    headers: {
      "Content-Type": "text/plain",
    },
  }
  const result = await axios(config);
  return result
}

export const createTournamentRegistrations = async (data) => {
  const config = {
    method: 'post',
    url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/tournaments/${data.tournamentId}/registrations`,
    headers: {
      'Content-Type': 'text/plain',
    },
    data
  };

  const result = await axios(config)
  return result
}

export const updateTournamentRegistrations = async (data) => {
  const config = {
    method: 'put',
    url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/tournaments/${data.tournamentId}/registrations`,
    headers: {
      'Content-Type': 'text/plain',
    },
    data
  };

  const result = await axios(config)
  return result
}

export const postFeedback = async (data) => {
  const config = {
    method: "post",
    url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/feedbacks`,
    headers: {
      "Content-Type": "text/plain",
    },
    data,
  };
  const result = await axios(config)
  return result
}

export const createUser = async (data) => {
  const config = {
    method: "post",
    url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/users`,
    headers: {
      "Content-Type": "text/plain",
    },
    data,
  };
  const result = await axios(config)
  return result
}

export const updateUser = async (data) => {
  const config = {
    method: 'put',
    url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/users/${data.id}`,
    headers: {
      'Content-Type': 'text/plain',
    },
    data
  };

  const result = await axios(config)
  return result
}

export const createJournalEntry = async (data) => {
  const config = {
    method: "post",
    url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/journal`,
    headers: {
      "Content-Type": "text/plain",
    },
    data,
  };
  const result = await axios(config)
  return result
}

export const getJournalEntryById = async (journalId) => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/journal/${journalId}`,
    headers: {
      "Content-Type": "text/plain",
    },
  }
  const result = await axios(config);
  return result
}

export const updateJournalEntryById = async (data) => {
  const config = {
    method: 'put',
    url: `${BASE_URL_PROD}/journal/${data.id}`,
    headers: {
      'Content-Type': 'text/plain',
    },
    data
  };

  const result = await axios(config)
  return result
}

export const getJournalentriesByUserId = async (userId) => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/journal/user/${userId}`,
    headers: {
      "Content-Type": "text/plain",
    },
  }
  const result = await axios(config);
  return result
}

export const createCourtLocation = async (data) => {
  const config = {
    method: "post",
    url: `${BASE_URL_PROD}/courts`,
    headers: {
      "Content-Type": "text/plain",
    },
    data,
  };
  const result = await axios(config)
  return result
}

export const getUserCreatedCourts = async () => {
  const config = {
    method: "get",
    url: `${BASE_URL_PROD}/courts`,
    headers: {
      "Content-Type": "text/plain",
    },
  }
  const result = await axios(config);
  return result
}

export const createPlay = async (data) => {
  const config = {
    method: "post",
    url: `${BASE_URL_PROD}/plays`,
    headers: {
      "Content-Type": "text/plain",
    },
    data,
  };
  const result = await axios(config)
  return result
}