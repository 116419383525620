import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  Box,
  Container,
  Typography,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import useResponsive from "../hooks/useResponsive";

export const FormV3 = (params) => {
  document.title = 'Playgrade | Feedback'
    const { matchId } = useParams();
    const isDesktop = useResponsive('up', 'lg');
    const defaultOptions = ["Limited", "Developing", "Average", "Proficient", "Outstanding"];
    const [formData, setFormData] = useState([
        {
            name: "Forehand",
            key: "forehand",
            role: "General proficiency: consistency, pace, placement etc.",
            value: "Average",
            options: defaultOptions,
        },
        {
            name: "Backhand",
            key: "backhand",
            role: "General proficiency: consistency, pace, placement etc.",
            value: "Average",
            options: defaultOptions,
        },
        {
            name: "Volley",
            key: "volley",
            role: "General proficiency: consistency, pace, placement etc.",
            value: "Average",
            options: defaultOptions,
        },
        {
            name: "Slice",
            key: "slice",
            role: "General proficiency: consistency, pace, placement etc.",
            value: "Average",
            options: defaultOptions,
        },
        {
            name: "Serve",
            key: "serve",
            role: "Placement, Speed, dependability etc.",
            value: "Average",
            options: defaultOptions,
        },
        {
            name: "Return",
            key: "return",
            role: "Return of serve: consistency, pace, placement etc.",
            value: "Average",
            options: defaultOptions,
        },
        {
            name: "Court Coverage",
            key: "courtCoverage",
            role: "How well does the player cover the court?",
            value: "Average",
            options: defaultOptions,
        },
        {
            name: "Stamina",
            key: "stamina",
            role: "Were you able to tire them out?",
            value: "Average",
            options: defaultOptions,
        },
        {
            name: "Overall Strategy",
            key: "strategy",
            role: "General proficiency: consistency, pace, placement etc.",
            value: "Aggressive",
            options: ["Aggressive", "Defensive"],
        },
      ]);
      const [matchDetails, setMatchDetails] = useState({});
      const [matchFeedback, setMatchFeedback] = useState({});
  
      useEffect(() => {
        const getMatchDetails = () => {
            const config = {
            method: "get",
            url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/plays/${matchId}`,
            headers: {
                "Content-Type": "application/json"
            },
            };
        
            axios(config)
            .then( (response) => {
                setMatchDetails(response.data);
            })
            .catch( (error) => {
                console.log(error);
            });
        
            }
        getMatchDetails();

        const getFeedback = () => {
            const config = {
            method: "get",
            url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/feedbacks/plays/${matchId}`,
            headers: {
                "Content-Type": "application/json"
            },
            };
        
            axios(config)
            .then( (response) => {
                const result = response.data.filter((feedback) => feedback.feedbackType === params.type);
                setMatchFeedback(result);
                if (result.length > 0) {
                    const matchFeedbackData = result[0];
                    Object.keys(matchFeedbackData).forEach((key) => {
                      formData.forEach((formItem) => {
                        if (key === formItem.key) {
                          formItem.value = matchFeedbackData[key];
                        }
                      });
                    });
                  }
            })
            .then(()=>{
                
            }
            )
            .catch( (error) => {
                console.log(error);
            });
        
            }
        getFeedback();
        }, []);

        const invertScore = (score) => {
          if (!score) {
            return "";
          }
          const sets = score.split(",");
          const invertedSets = sets.map((set) => {
            const setScore = set.split("(");
            if (setScore.length === 1) {
              return setScore[0].split("-").reverse().join("-");
            }
            if (setScore.length === 2) {
              const invertedGames = setScore[0].trim().split("-").reverse().join("-");
              const invertedTieBreak = setScore[1].replace(")", "").trim().split("-").reverse().join("-");
              console.log(invertedGames, invertedTieBreak)
              return `${invertedGames}(${invertedTieBreak})`;
            }
            return ''
          });
          return invertedSets.join(",");
        };
        
        
      console.log(matchDetails)

      const stats = [
        { name: "Opponent" , stat: matchDetails.createdByName },
        { name: "Final Score", stat: params.type==="self"?  matchDetails.score : invertScore(matchDetails.score) },
        ...(params.type === "others" ? [{ name: "Requested by", stat: matchDetails.createdByName }] : []),
        { name: "Date", stat: new Date(matchDetails.datetime).toLocaleDateString() },
        { name: "Duration", stat: `${matchDetails.durationHours} hrs ${matchDetails.durationMinutes} min` },
        { name: "Location", stat: matchDetails.courtLocation },
        ]

      // const { currentUser } = useContext(AuthContext);

      const handleChange = (name, value) => {
        setFormData((prevFormData) =>
          prevFormData.map((item) => (item.name === name ? { ...item, value } : item))
        );
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        const dbData = {};
        formData.forEach((item) => {
            dbData[item.key] = item.value;
        });
        const predata = { ...dbData, opponent:matchDetails.opponent, id: uuidv4(), playId: matchId, feedbackType : params.type, createdByEmail: matchDetails.createdByEmail, createdByUserId: matchDetails.createdByUserId}
        const data = {
          body: predata,
          context: {},
        };
    
        const config = {
          method: "post",
          url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/feedbacks`,
          headers: {
            "Content-Type": "text/plain",
          },
          data: predata,
        };
    
        axios(config)
          .then( (response) => {
            console.log(response.data);
          })
          .catch( (error) => {
            console.log(error);
          });

        // if (currentUser){
        // window.location.href = "/#/dashboard";
        // }
        // else{
        window.location.href = "/";
        // }

      };
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Match Statistics
      </Typography>
      <Stack
            direction={!isDesktop ? 'column' : 'row'}
            alignItems={!isDesktop ? 'stretch' : 'center'}
            justifyContent="space-between"
            mt={5}
            mb={2}
            spacing={!isDesktop ? 2 : 0}
          >
        {stats.map((item) => (
          <Box key={item.name} boxShadow={1} p={2}>
            <Typography variant="body1">{item.name}</Typography>
            <Typography variant="h6">{item.stat}</Typography>
          </Box>
        ))}
      </Stack>

      <Typography variant="h4" gutterBottom marginTop={3}>
        Feedback
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack direction="column" alignItems="center" justifyContent="space-between" mt={5} spacing={2}>
          {formData.map((attribute) => (
            <Box
              key={attribute.name}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid",
                borderColor: "grey.300",
                borderRadius: "4px",
                width: isDesktop ? "700px" : "300px",
                p: 2,
                "&:hover": {
                  borderColor: "grey.400",
                },
              }}
            >
              <Typography variant="body1" fontWeight="bold" color="text.primary" marginRight={2}>
                {attribute.name}
              </Typography>
              <div>
                {attribute.options.map((option, index) => (
                  <Button
                    key={option}
                    variant="outlined"
                    size="small"
                    sx={{
                      mr: 1,
                      mt: 1,
                      borderRadius: 0,
                      ":hover": {
                        backgroundColor: "#2065D1",
                        color: "white",
                      },
                      backgroundColor: attribute.value === option ? "#2065D1" : "white",
                      color: attribute.value === option ? "white" : "text.primary",
                      "&:first-child": {
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      },
                      "&:last-child": {
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      },
                    }}
                    onClick={() => handleChange(attribute.name, option)}
                    disabled={matchFeedback.length !== 0}
                  >
                    {option}
                  </Button>
                ))}
              </div>
            </Box>
          ))}
        </Stack>

        {matchFeedback.length === 0 && (
          <Stack direction="row" alignItems="center" justifyContent="center" mt={6}>
            <Button type="submit" variant="contained" size="large">
              Submit
            </Button>
          </Stack>
        )}
      </form>
    </Container>
  );
};
