import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import {
    Box,
    Container,
    Typography,
    CircularProgress,
    Stack,
    Button,
    TextField,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../utils/auth';
import { getUserByUserId, updateUser } from '../api/ApiGateway';
import useResponsive from '../hooks/useResponsive';
import { getUserByEmailOrPhone } from '../utils/getUser';


export default function UserProfilePage() {
    const { userId } = useParams();
    document.title = 'User Profile | Playgrade';

    const isDesktop = useResponsive('up', 'lg');
    const { currentUser } = useContext(AuthContext);
    const [currentUserId, setCurrentUserId] = useState('');
    const [user, setUser] = useState({});
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [ustaRating, setUstaRating] = useState('');
    const [utrRating, setUtrRating] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditable, setIsEditable] = useState(false);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        // Reset the form values if editing is canceled
        setFullName(user.displayName || '');
        setEmail(user.email || '');
        setUstaRating(user.ustaRating || '');
        setUtrRating(user.utrRating || '');
    };

    const handleSaveClick = async () => {
        // You can add logic to save the changes to the backend here
        setIsEditing(false);

        // Assuming you want to update the user's display name and email in the AuthContext
        // Note: You should implement actual backend calls to update user information
        // For demonstration purposes, I'm updating the context directly, which is not a good practice.
        // In a real-world scenario, you should call a backend API to update user information.
        user.displayName = fullName
        user.email = email;
        user.ustaRating = ustaRating;
        user.utrRating = utrRating;
        const { id, ...userValues } = user

        const updateUserData = {
            id: userId,
            attributes: userValues
        }
        await updateUser(updateUserData);
        // Assuming ustaRating and utrRating are stored in the backend, update them accordingly
        // You should implement actual backend calls to update USTA and UTR ratings
    };

    const checkEditAccess = (currentUserId, userData) => {
        console.log(currentUserId, userData)
        if (currentUserId === userData.id) {
            return true
        }

        if (currentUserId === userData.createdByUserId) {
            return true
        }

        return false
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const loggedInUserId = await getUserByEmailOrPhone(currentUser)
                setCurrentUserId(loggedInUserId)
                const userData = await getUserByUserId(userId);
                if (userData) {
                    setUser(userData?.data);
                    setIsEditable(checkEditAccess(currentUserId, userData?.data))
                    setFullName(userData?.data.displayName || '');
                    setEmail(userData?.data.email || '');
                    setUstaRating(userData?.data.ustaRating || '');
                    setUtrRating(userData?.data.utrRating || '');
                }
                setIsLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsLoading(false)
            }
        };

        fetchData(); // Call the async function immediately

    }, [currentUser, userId, currentUserId]);

    return (
        <>
            <Helmet>
                <title>User Profile | Playgrade</title>
            </Helmet>

            <Container>
                {isLoading ? (
                    // Render loading indicator
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: 'calc(100vh - 64px)', // Adjust this value based on your layout
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (<>
                    <Stack
                        direction={!isDesktop ? 'column' : 'row'}
                        alignItems={!isDesktop ? 'stretch' : 'center'}
                        justifyContent="space-between"
                        mb={5}
                        spacing={!isDesktop ? 2 : 0}
                    >
                        <Typography variant="h4" gutterBottom>
                            User Profile
                        </Typography>
                        {isEditable && <>
                            {!isEditing ? (
                                <Button variant="outlined" onClick={handleEditClick}>
                                    Edit Profile
                                </Button>
                            ) : (
                                <Stack direction="row" spacing={2}>
                                    <Button variant="contained" onClick={handleSaveClick}>
                                        Save
                                    </Button>
                                    <Button variant="outlined" onClick={handleCancelClick}>
                                        Cancel
                                    </Button>
                                </Stack>
                            )}
                        </>}
                    </Stack>

                    <Box sx={{ p: 2 }}>
                        <form>
                            <TextField
                                label="Full Name"
                                value={fullName}
                                fullWidth
                                disabled={!isEditing}
                                onChange={(e) => setFullName(e.target.value)}
                                sx={{ marginTop: 1, marginBottom: 1 }}
                            />

                            <TextField
                                label="Email"
                                value={email}
                                fullWidth
                                disabled={!isEditing}
                                onChange={(e) => setEmail(e.target.value)}
                                sx={{ marginTop: 1, marginBottom: 1 }}
                            />

                            <TextField
                                label="USTA Rating"
                                value={ustaRating}
                                fullWidth
                                disabled={!isEditing}
                                onChange={(e) => setUstaRating(e.target.value)}
                                sx={{ marginTop: 1, marginBottom: 1 }}
                            />

                            <TextField
                                label="UTR Rating"
                                value={utrRating}
                                fullWidth
                                disabled={!isEditing}
                                onChange={(e) => setUtrRating(e.target.value)}
                                sx={{ marginTop: 1, marginBottom: 1 }}
                            />
                        </form>
                    </Box>
                </>
                )}
            </Container>
        </>
    );
}
