import React, { useState, useEffect } from "react";
import { Container, Box, Stack, CircularProgress, Typography, Link, TextField, Button, Divider } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getAuth, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import useResponsive from '../hooks/useResponsive';
import Iconify from "../components/iconify/Iconify";
import { getUserByEmailOrPhone } from "../utils/getUser";

export const LoginPage = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'lg');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // State to hold authentication errors
  const [emailAddressError, setEmailAddressError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState('+91');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          setRecaptchaVerified(true);
        },
        'expired-callback': () => {
          setRecaptchaVerified(false);
        }
      }, auth);
      window.recaptchaVerifier.render();
    }
  }, []);

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
    setError(null);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSendOtp = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!recaptchaVerified) {
      window.recaptchaVerifier.render();
    }
    const appVerifier = window.recaptchaVerifier;
    try {
      const existingUser = await getUserByEmailOrPhone({ phoneNumber });
      if (!existingUser) {
        throw new Error('User not found. Please create an account before logging in');
      }
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      window.confirmationResult = confirmationResult;
      setOtpSent(true);
    } catch (error) {
      setError(error.message);
    }
    finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const result = await window.confirmationResult.confirm(otp);
      if (result.user) {
        window.location.href = "/dashboard";
      }
    } catch (error) {
      setError(error.message);
    }
    finally {
      setLoading(false);
    }
  };

  if (auth.currentUser) {
    window.location.href = "/dashboard";
  }

  const handleEmailChange = (event) => {
    setEmailAddressError(null);
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPasswordError(null);
    setPassword(event.target.value);
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    try {
      setError(null);
      await sendPasswordResetEmail(auth, email);
      setResetSuccess(true); // Set reset success message
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;

      switch (errorCode) {
        case 'auth/invalid-email':
          setEmailAddressError('Invalid email address');
          break;
        case 'auth/wrong-password':
          setPasswordError('Incorrect password');
          break;
        case 'auth/user-not-found':
          setEmailAddressError('User not found');
          break;
        case 'auth/missing-email':
          setEmailAddressError('Email address is required');
          break;
        default:
          setError(errorMessage);
      }
    }
  };

  const handleDontHaveAccountClick = (event) => {
    event.preventDefault();
    window.location.href = "/register";
  };

  const handleEmailSignIn = async (event) => {
    event.preventDefault();
    try {
      setError(null); // Clear previous errors
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          window.location.href = "/dashboard";
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;

          switch (errorCode) {
            case 'auth/invalid-email':
              setEmailAddressError('Invalid email address');
              break;
            case 'auth/wrong-password':
              setPasswordError('Incorrect password');
              break;
            case 'auth/user-not-found':
              setEmailAddressError('User not found');
              break;
            case 'auth/missing-email':
              setEmailAddressError('Email address is required');
              break;
            default:
              setError(errorMessage);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoogleSignUp = async (event) => {
    event.preventDefault();
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (user) {
        await createUserInternal(user);
        navigate("/dashboard");
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
    }
  };

  const createUserInternal = async (user) => {
    console.log('Creating user in internal database');
    console.log('user', user);
    try {
      const createInternalUserConfig = {
        method: 'post',
        url: 'https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/users',
        headers: {
          'Content-Type': 'text/plain',
        },
        data: {},
      };

      const existingUser = await getUserByEmailOrPhone(user);
      console.log('existingUser', existingUser);
      if (!existingUser) {
        const data = {
          displayName: user.displayName,
          signupSource: user.providerId,
          sourceId: user.uid,
          id: uuidv4(),
          phoneNumber: user.phoneNumber,
          email: user.email,
          signedUpAt: new Date().toISOString(),
        };
        createInternalUserConfig.data = data;

        const response = await axios(createInternalUserConfig);
      } else {
        const updateInternalUserConfig = {
          method: 'put',
          url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/users/${existingUser}`,
          headers: {
            'Content-Type': 'text/plain',
          },
          data: {
            id: existingUser,
            attributes: {
              displayName: user.displayName,
              signupSource: user.providerId,
              sourceId: user.uid,
              phoneNumber: user.phoneNumber,
              email: user.email,
            }
          }
        };
        console.log('The user already exists, updating records');

        const response = await axios(updateInternalUserConfig);
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | Playgrade</title>
      </Helmet>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #e0e0e0",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "50px",
            borderRadius: "10px",
            maxWidth: 500,
            margin: "0 auto",
            mt: isDesktop ? "6vh" : 5,
          }}
        >
          <Stack spacing={3} sx={{ width: "100%" }}>
            <Stack
              direction={!isDesktop ? 'column' : 'row'}
              alignItems={!isDesktop ? 'stretch' : 'center'}
              justifyContent="space-between"
              spacing={!isDesktop ? 2 : 0}
            >
              <Typography variant="h4">Login</Typography>
              <Typography component={Link} variant="body2" sx={{ textDecoration: 'none', textAlign: 'right', color: 'primary', cursor: 'pointer' }} onClick={handleDontHaveAccountClick}>
                Don't have an account?
              </Typography>
            </Stack>

            <Divider>
              <Typography variant="body2">Login with Phone</Typography>
            </Divider>
            <TextField
              label="Phone Number"
              fullWidth
              variant="outlined"
              sx={{ my: 1 }}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
            {!otpSent && (
              <Button fullWidth variant="contained" color="primary" onClick={handleSendOtp} id="send-otp-button" sx={{ height: '50px' }}>
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Send OTP'}
              </Button>
            )}
            {otpSent && (
              <>
                <TextField
                  label="OTP"
                  fullWidth
                  variant="outlined"
                  sx={{ my: 1 }}
                  value={otp}
                  onChange={handleOtpChange}
                />
                <Button fullWidth variant="contained" color="primary" onClick={handleVerifyOtp} sx={{ height: '50px' }}>
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Verify OTP'}
                </Button>
              </>
            )}
            {error && <Typography color="error">{error}</Typography>}
            <Divider>
              <Typography variant="body2">Login with Email</Typography>
            </Divider>
            <TextField
              label="Email Address"
              fullWidth
              variant="outlined"
              sx={{ my: 1 }}
              value={email}
              onChange={handleEmailChange}
              error={emailAddressError !== null}
              helperText={emailAddressError}
            />
            <TextField
              label="Password"
              fullWidth
              variant="outlined"
              type="password"
              sx={{ my: 1 }}
              value={password}
              onChange={handlePasswordChange}
              error={passwordError !== null}
              helperText={passwordError}
            />
            <Typography
              component={Link}
              to="/forgot-password"
              variant="body2"
              onClick={handleForgotPassword}
              sx={{ textDecoration: 'none', textAlign: 'right', color: 'primary', cursor: 'pointer' }}
            >
              Forgot password?
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleEmailSignIn}
              sx={{ my: 1, height: '50px' }}
            >
              Login
            </Button>
            <Divider>
              <Typography variant="body2">Login with</Typography>
            </Divider>
            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{ '& .MuiButton-startIcon': { mr: !isDesktop ? 0 : 1, ml: !isDesktop ? 0 : -0.5 } }}>
              <Button variant="outlined" color="primary" startIcon={<Iconify icon="mdi:google" />} onClick={handleGoogleSignUp}>
                {isDesktop && 'Google'}
              </Button>
            </Stack>
          </Stack>
          <div id="recaptcha-container"><></></div>
        </Box>
      </Container>
    </>
  );
};
