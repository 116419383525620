import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ReactGA from 'react-ga';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  CircularProgress,
  TableRow,
  MenuItem,
  Menu,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Chip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
// components
import { getCourtRadarCourts } from '../api/ApiGateway';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import allCourtsData from '../utils/tln_allcourts.json'
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { AuthContext } from '../utils/auth';
import { WanderingAround } from './UnauthenticatedView';
import { invertScore } from './SelfFeedbackForm';
import { getUserByEmailOrPhone } from '../utils/getUser';
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'courtName', label: 'Name', alignRight: false },
  { id: 'nCourts', label: '# Courts', alignRight: false },
  { id: 'isLighted', label: 'Lighted Courts', alignRight: false },
  { id: 'isFee', label: 'Fee', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user['Location Name'].toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [USERLIST, setUSERLIST] = useState([]);
  const [feedbacksForUser, setFeedbacksForUser] = useState({});
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    async function fetchData() {
      const getFeedbacksForUser = async () => {
        const userIdFromResponse = await getUserByEmailOrPhone(currentUser);
        const config = {
          method: "get",
          url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/feedbacks/users/${userIdFromResponse}`,
          headers: {
            "Content-Type": "application/json"
          },
        };

        axios(config)
          .then((response) => {
            const data = response.data;
            const feedbacks = {};
            data.forEach((feedback) => {
              feedbacks[feedback.playId] = true;
            });
            setFeedbacksForUser(feedbacks);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      const fetchCourts = async () => {
        try {
          //   const courtsResponse = await getCourtRadarCourts();
          setUSERLIST(allCourtsData);
        } catch (error) {
          console.log(error);
        }
      };
      try {
        setIsLoading(true)
        if (currentUser) {
          await getFeedbacksForUser();
          await fetchCourts();
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error)
        setIsLoading(false)
      }
    }
    fetchData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property === 'courtName' ? 'locationName' : property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.opponent);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length) : 0;

  const isNotFound = !filteredUsers.length && !!filterName;

  //   if (!currentUser) {
  //     return <WanderingAround />;
  //   }

  return (
    <>
      <Helmet>
        <title> Court Radar | Playgrade </title>
      </Helmet>

      <Container>
        {isLoading ? (
          // Render loading indicator
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 'calc(100vh - 64px)', // Adjust this value based on your layout
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Tennis Courts
              </Typography>
            </Stack>

            <Card>
              <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={USERLIST.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      showCheckBox={false}
                    />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { 'Location Name': locationName, 'Courts': nCourts, id, 'Lighted': isLighted, 'Fee': isFee } = row;
                        return (
                          <>
                            <TableRow hover key={id} tabIndex={-1}>

                              <TableCell align="left">
                                {/* Use Link component to create a hyperlink */}
                                <Link
                                  to={`/v2/courts/${id}`}
                                  style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                  {locationName}
                                </Link>
                              </TableCell>
                              <TableCell align="left">{nCourts}</TableCell>
                              <TableCell align="left">
                                {isLighted === true ? "Available" : "Not Available"}
                              </TableCell>
                              <TableCell align="left">
                                {isFee === true ? "Court Fee" : "Public Court"}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>


                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers.length}  // Use filteredUsers.length instead of USERLIST.length
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
        )}
      </Container>
    </>
  );
}
