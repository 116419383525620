// Import necessary libraries and components
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Stack, Typography } from '@mui/material'; // Adjust import paths as needed
import { useTheme } from '@mui/material/styles';
import ReactGA from 'react-ga';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // Make sure to import useParams
import Iconify from '../components/iconify';
import {
    AppTasks,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates,
  } from '../sections/@dashboard/app';
import { getCourtInsightsById } from '../api/ApiGateway';

// Other imports...

const CourtInsightsPage = () => {
  const [insightsData, setInsightsData] = useState(null);
  const theme = useTheme();
  const { courtId } = useParams(); // Use useParams to get courtId

  const fetchTennisCourtData = async () => {
    try {
      const insightsResponse = await getCourtInsightsById(courtId);
      setInsightsData(insightsResponse.data);
      console.log(insightsResponse)
    } catch (error) {
      console.error('Error fetching tennis court data:', error);
    }
  };

  useEffect(() => {
    // Track pageview with react-ga
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Other analytics code...

    // Clean up if necessary
    return () => {
      // Clean up code...
    };
  }, []); 

  useEffect(() => {
    fetchTennisCourtData();
  }, [courtId]);

  if (!insightsData) {
    // Loading state or error handling
    return null;
  }
  
  const {
    court_name: courtName,
    full_address: fullAddress,
    is_fee: isFee,
    is_lighted: isLighted,
    nCourts,
    is_tennis_club: isTennisClub,
    is_tennis_store: isTennisStore,
    total_matches_last_year: totalMatchesLastYear,
    total_matches_last_month: totalMatchesLastMonth,
    total_matches_last_week: totalMatchesLastWeek,
    total_matches: totalMatches,
    avg_matches_per_weekday: avgMatchesPerWeekday,
    avg_play_time_per_weekday: avgPlayTimePerWeekday,
  } = insightsData;
  console.log('insightsData', insightsData)
  
  // Check if avgMatchesPerWeekday and avgPlayTimePerWeekday are available
  const formattedAvgMatchesPerWeekday = avgMatchesPerWeekday
    ? Object.entries(avgMatchesPerWeekday).map(([label, value]) => ({ label, value }))
    : false;
  console.log('formattedAvgMatchesPerWeekday', formattedAvgMatchesPerWeekday)
  const formattedAvgPlayTimePerWeekday = avgPlayTimePerWeekday
    ? Object.entries(avgPlayTimePerWeekday).map(([label, value]) => ({ label, value }))
    : false;
  return (
    <>
    <Helmet>
      <title>{`${courtName} | Playgrade Court Radar`}</title>
      <meta name="description" content={`Explore ${courtName}, a premier tennis court located at ${fullAddress}. Enjoy the thrill of playing on well-maintained, ${isLighted ? 'illuminated' : 'daylight-filled'} courts. Offering a dynamic tennis experience, ${courtName} stands out with ${nCourts} courts, ${isTennisClub ? 'exclusive club amenities,' : ''} and ${isTennisStore ? 'on-site tennis equipment store.' : ''} Discover a community that values both competition and camaraderie, with a total of ${totalMatches} matches played. Last year alone, participants engaged in ${totalMatchesLastYear} matches, showcasing the court's popularity. Join us for an unparalleled tennis experience, whether you're a seasoned player or just starting out. ${isFee ? 'Fee applies.' : 'Free access available.'} 🎾`}/>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
    <Container maxWidth="xl">
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {courtName}
          </Typography>
          <Typography variant="body1" gutterBottom>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#007BFF', textDecoration: 'underline' }}
        >
          {fullAddress}
        </a>
      </Typography>
      </Stack>
      {/* Your JSX code for the court insights page */}
      <Grid container spacing={3}>
        {nCourts!==0 && <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Courts" total={nCourts}  />
        </Grid>}
        {isLighted && <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Lights?" total={isLighted==='True' ? <Iconify icon={'mdi:like'} width={30}/> : <Iconify icon={'mdi:dislike'} width={30}/>}  />
        </Grid>}
        {isFee && <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Fee?" total={isFee==='True' ? <Iconify icon={'mdi:like'} width={30}/> : <Iconify icon={'mdi:dislike'} width={30}/>}  />
        </Grid>}
        {isTennisStore && <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Store?" total={isTennisStore==='True' ? <Iconify icon={'mdi:like'} width={30}/> : <Iconify icon={'mdi:dislike'} width={30}/>}  />
        </Grid>}
        {totalMatchesLastYear !== undefined && (
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Matches Played Last Year" total={totalMatchesLastYear}  />
          </Grid>
        )}
        {totalMatchesLastMonth !== undefined && (
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Matches Played Last Month" total={totalMatchesLastMonth}  />
          </Grid>
        )}
        {totalMatchesLastWeek !== undefined && (
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Matches Played Last Week" total={totalMatchesLastWeek}  />
          </Grid>
        )}
        {totalMatches !== undefined && (
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Matches" total={totalMatches}  />
          </Grid>
        )}

        {formattedAvgMatchesPerWeekday && <Grid item xs={12} md={6} lg={6}>
          <AppConversionRates
            title="Average Matches"
            chartData={formattedAvgMatchesPerWeekday}
            unit=''
            isHorizontal={false}
          />
        </Grid>}

        {formattedAvgPlayTimePerWeekday && <Grid item xs={12} md={6} lg={6}>
          <AppConversionRates
            title="Average Play Time"
            chartData={formattedAvgPlayTimePerWeekday}
            unit='hrs'
            isHorizontal={false}
          />
        </Grid>}
      </Grid>
    </Container>
    </>
  );
};

export default CourtInsightsPage;
