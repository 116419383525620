import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Stack } from '@mui/material';
import { getAuth } from 'firebase/auth';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify/Iconify';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'relative',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  const auth = getAuth();
  const user = auth.currentUser;
  const isDesktop = useResponsive('up', 'lg');
  console.log(window.location.pathname)
  const isLoginOrRegister = window.location.pathname === '/login' || window.location.pathname === '/register';
  return (
    <>
      <StyledHeader>
        <Stack direction="row" alignItems="center" justifyContent="space-between" >
        <Logo 
        sx = {{height: isDesktop ? '100%' : 40}}
        />
        {!isLoginOrRegister && <Button
          variant="contained"
          component="a"
          href={user ? "/v2/dashboard":"/login"}
          sx={{ height: 40, mr: 2 }}
          startIcon={<Iconify icon={ user ? "iconamoon:home-bold":"material-symbols:login"} width={20} height={20} />}
        >
          {user ? "Home":"Login"}
        </Button>}
        </Stack>
      </StyledHeader>
      <Outlet />
    </>
  );
}
