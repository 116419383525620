import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, TextField, Stack, Snackbar } from '@mui/material';
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '70vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export const WanderingAround = () => {
  const [emailAddress, setEmailAddress] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const isDesktop = useResponsive('up', 'lg');
  const handleChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const predata = { "email": emailAddress, id: String(Math.random()) };
    
    const config = {
      method: "post",
      url: "https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/waitlist",
      headers: {
        "Content-Type": "text/plain",
      },
      data: predata,
    };

    axios(config)
      .then( (response) =>{
        console.log(JSON.stringify(response.data));
      })
      .catch( (error) =>{
        console.log(error);
      });
    setShowSuccessMessage(true);
    setEmailAddress('');

  };

  const handleSnackbarClose = () => {
    setShowSuccessMessage(false);
  };
  return (
    <>
      <Helmet>
        <title> Playgrade | Wandering around....  </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            You don't seem to be one of us?
          </Typography>

          <Typography sx={{ color: 'text.secondary', marginBottom: 3 }}>
            Would you like to access the cool dashboards and analytics? We are currently in closed beta. Join our waitlist to get access.
          </Typography>

          {/* <Box
            component="img"
            src="/assets/illustrations/illustration_404.svg"
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          /> */}
          <Stack
            direction={!isDesktop ? 'column' : 'row'}
            alignItems={!isDesktop ? 'stretch' : 'center'}
            justifyContent="space-between"
            spacing={!isDesktop ? 2 : 0}
          >
          <TextField
                label="Email Address"
                value={emailAddress}
                onChange={handleChange}
                inputProps={{ min: 0, max: 59 }}
                sx={{ marginTop: 1, marginBottom: 1, minWidth: 250, marginRight: isDesktop ? 2: 0}}
                required
          />
          <Button sx={{minWidth:200}}size="large" variant="contained" onClick={handleSubmit}>
            Join our waitlist
          </Button>
          </Stack>
          <Snackbar
            open={showSuccessMessage}
            autoHideDuration={3000} // Adjust the duration as needed
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            message="You are on the waitlist!"
          />
        </StyledContent>
      </Container>
    </>
  );
}
