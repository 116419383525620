import { getUserIdFromEmail, getUserIdFromPhoneNumber } from '../api/ApiGateway';

export async function getUserByEmailOrPhone(currentUser) {
    console.log('currentUser', currentUser);
    if (currentUser.email) {
        const loggedInUserId = await getUserIdFromEmail(currentUser.email);
        return loggedInUserId;
    }
    if (currentUser.phoneNumber) {
        console.log('looking up user by phone number', currentUser.phoneNumber);
        const loggedInUserId = await getUserIdFromPhoneNumber(currentUser.phoneNumber);
        return loggedInUserId;
    }
    return null;
}