import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Container,
  Typography,
  Stack,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  createFilterOptions
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReactGA from 'react-ga';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AuthContext } from '../utils/auth';
import useResponsive from '../hooks/useResponsive';
import allCourtsData from '../utils/tln_allcourts.json'
import { getUserByEmailOrPhone } from '../utils/getUser';

const filter = createFilterOptions();

function getTennisMatchResult(score) {
  const sets = score.split(',');

  let player1SetsWon = 0;
  let player2SetsWon = 0;

  sets.forEach(set => {
    const [gamesPlayer1, gamesPlayer2] = set.split('-').map(Number);

    if (gamesPlayer1 > gamesPlayer2) {
      player1SetsWon += 1;
    } else if (gamesPlayer2 > gamesPlayer1) {
      player2SetsWon += 1;
    }
    // If games are equal, no winner for the set
  });

  if (player1SetsWon > player2SetsWon) {
    return {
      winner: 0, // Player 1 wins
      score
    };
  }

  if (player2SetsWon > player1SetsWon) {
    // Player 2 wins, flip the score
    const flippedScore = sets.map(set => {
      const [gamesPlayer1, gamesPlayer2] = set.split('-').map(Number);
      return `${gamesPlayer2}-${gamesPlayer1}`;
    }).reverse().join(',');

    return {
      winner: 1, // Player 2 wins
      score: flippedScore
    };
  }

  // Draw, both players won the same number of sets
  return {
    winner: -1,
    score
  };
}

export default function CreateTournamentPage(props) {
  document.title = 'New Tournament | Playgrade';
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });
  const isDesktop = useResponsive('up', 'lg');
  const [opponent, setOpponent] = useState('');
  const [players, setPlayers] = useState([]);
  const [score, setScore] = useState('');
  const [tournamentName, setTournamentName] = useState('');
  const [fee, setFee] = useState('');
  const [tournamentType, setTournamentType] = useState('');
  const [courtLocation, setCourtLocation] = useState({});
  const [datetime, setDateTime] = useState(new Date());
  const [lastDateToRegister, setLastDateToRegister] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [notes, setNotes] = useState('');
  const [matchType, setMatchType] = useState(props.type || 'Competitive');
  const [tags, setTags] = useState([]);
  const [newOpponent, setNewOpponent] = useState(false)
  const [newPlayer, setNewPlayer] = useState(false)
  const [loggedInUserId, setLoggedInUserId] = useState({});
  const [isLoading, setIsLoading] = useState(true);


  const handleOpponentChange = (event, newValue) => {
    console.log(newValue)
    if (typeof newValue === 'string') {
      setOpponent({
        id: newValue,
        displayName: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      setOpponent({
        id: uuidv4(),
        displayName: newValue.inputValue,
      });
      setNewOpponent(true)
    } else {
      setOpponent(newValue);
    }
  };

  const handlePlayerChange = (event, newPlayers) => {
    setPlayers(newPlayers);
  };

  const handleTournamentNameChange = (event) => {
    setTournamentName(event.target.value);
  };

  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };

  const handleFeeChange = (event) => {
    setFee(event.target.value);
  };

  const handleTournamentTypeChange = (event) => {
    setTournamentType(event.target.value);
  };

  const handleTournamentLocationChange = (event, value) => {
    setCourtLocation(value);
  };

  const handleStartDateTimeChange = (newDate) => {
    setDateTime(newDate);
  };

  const handleLastDateToRegisterChange = (newDate) => {
    setLastDateToRegister(newDate);
  };

  const handleTimeChange = (newTime) => {
    setTime(newTime);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleTagsChange = (event, value) => {
    setTags(value);
  };

  const { currentUser } = useContext(AuthContext);
  const userEmail = currentUser.email;
  const userName = currentUser.displayName;
  const [allCourtLocations, setallCourtLocations] = useState([]);
  const [allUsers, setallUsers] = useState([])
  const [isCoachMode, setIsCoachMode] = useState(false);
  const [isPlayersValid, setIsPlayersValid] = useState(true);

  const handleCoachModeToggle = () => {
    setIsCoachMode(!isCoachMode);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const userId = await getUserByEmailOrPhone(currentUser);
        console.log('user id is', userId);
        setLoggedInUserId(userId);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false)
      }
    };

    fetchData(); // Call the async function immediately

  }, [currentUser, loggedInUserId]);

  useEffect(() => {
    if (!isCoachMode) {
      // Set the default player value to [{ id: loggedInUserId, displayName: userName }]
      setPlayers([
        {
          id: loggedInUserId,
          displayName: userName,
        },
      ]);
    } else {
      setPlayers([]); // Clear players in coach mode
    }
  }, [isCoachMode, loggedInUserId, userName]);


  useEffect(() => {
    let cancelRequest = null;

    const getData = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-API-Key': '2LnZuXL3eoai8wCLbaQ8O67ZEghqX5C07e2uSMQE'
        };

        // Create a cancel token
        const source = axios.CancelToken.source();

        // Assign the cancel token to the cancelRequest variable
        cancelRequest = source;

        const courtResponse = await axios.get(`https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/courts`, {
          cancelToken: source.token,
        });

        setallCourtLocations(courtResponse.data);
        const userResponse = await axios.get(`https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/users`, {
          cancelToken: source.token,
        });

        setallUsers(userResponse.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled, do nothing.
        } else {
          console.log(error);
        }
      }
    };

    // Call the async function inside the effect.
    getData();

    // Clean up function: Cancel the request when the component unmounts.
    return () => {
      if (cancelRequest) {
        cancelRequest.cancel('Request canceled due to component unmount.');
      }
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newSessionDataV2 = {
      id: uuidv4(),
      tournamentName,
      hosts: [],
      createdBy: [
        {
          id: loggedInUserId,
          email: userEmail,
          name: userName,
          utrRating: '',
          ustaRating: '',
        },
      ],
      fee,
      tournamentType,
      courtLocation: courtLocation.name,
      courtName: courtLocation["Location Name"],
      courtId: courtLocation.id,
      notes,
      liveStatus: 'Created',
      draws: [],
      startDateTime: datetime,
      lastDateToRegister,
    };
    console.log('courtLocation', courtLocation)
    const sessionResult = getTennisMatchResult(score)
    const winnerFromScore = sessionResult.winner;
    newSessionDataV2.score = sessionResult.score;

    if (!isCoachMode) {
      newSessionDataV2.hosts = [
        {
          id: opponent.id,
          name: opponent.displayName,
        }
      ];
    }
    else {
      newSessionDataV2.hosts = [
        {
          id: loggedInUserId,
          name: userName,
        }
      ];
    }
    console.log(newSessionDataV2);

    const newSessionConfig = {
      method: "post",
      url: "https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/tournaments",
      headers: {
        "Content-Type": "text/plain",
      },
      data: newSessionDataV2,
    };

    const newUserConfig = {
      method: "post",
      url: "https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/users",
      headers: {
        "Content-Type": "text/plain",
      },
      data: {
        ...opponent,
      },
    }

    try {
      await axios(newSessionConfig);
      newUserConfig.data.createdByUserId = await getUserByEmailOrPhone(currentUser);
      if (newOpponent) {
        await axios(newUserConfig);
        console.log('User created');
      }
    } catch (error) {
      console.log(error);
    }

    window.location.href = "/v2/tournaments";
  };

  return (
    <>
      <Helmet>
        <title>New Session | Playgrade</title>
      </Helmet>

      <Container>
        <Stack
          direction={!isDesktop ? 'column' : 'row'}
          alignItems={!isDesktop ? 'stretch' : 'center'}
          justifyContent="space-between"
          mb={5}
          spacing={!isDesktop ? 2 : 0}
        >
          <Typography variant="h4" gutterBottom>
            New Tournament
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCoachMode}
                onChange={handleCoachModeToggle}
                color="primary"
              />
            }
            label="I am the host"
          />
        </Stack>

        <Box sx={{ p: 2 }}>
          <form onSubmit={handleSubmit}>
            <Stack
              direction={!isDesktop ? 'column' : 'row'}
              alignItems={!isDesktop ? 'stretch' : 'center'}
              justifyContent="space-between"
              mb={1}
              spacing={!isDesktop ? 0 : 2}
            >
              <TextField
                label="Name"
                value={tournamentName}
                onChange={handleTournamentNameChange}
                sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                fullWidth
                inputProps={{ min: 0 }}
                required
              />
              {/* <Autocomplete
              multiple
              value={players}
              onChange={handlePlayerChange}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;

                const isExisting = options.some(
                  (option) =>
                    inputValue === option.displayName ||
                    inputValue === option.inputValue
                );

                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    id: inputValue,
                    displayName: `+ ${inputValue}`,
                    inputValue,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-player"
              options={allUsers}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                return option.displayName;
              }}
              renderOption={(props, option) => <li {...props}>{option.displayName}</li>}
              sx={{ width: isDesktop ? '50%' : '100%', borderColor: isPlayersValid ? 'initial' : 'red' }}
              freeSolo
              renderInput={(params) => <TextField {...params} label="Players" />}
            />
            {(!isPlayersValid) && (
              <Typography variant="body2" color="error">
                Please select at least one player.
              </Typography>
            )} */}


              {!isCoachMode && <Autocomplete
                value={opponent}
                onChange={handleOpponentChange}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;

                  const isExisting = options.some(
                    (option) =>
                      inputValue === option.displayName ||
                      inputValue === option.inputValue
                  );

                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      id: inputValue,
                      displayName: `+ ${inputValue}`,
                      inputValue,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-opponent"
                options={allUsers}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.displayName;
                }}
                renderOption={(props, option) => <li {...props}>{option.displayName}</li>}
                sx={{ width: isDesktop ? '50%' : '100%' }}
                freeSolo
                renderInput={(params) => <TextField {...params} label="Host" required />}
              />}
              {/* <TextField
              label="Score"
              value={score}
              onChange={handleScoreChange}
              fullWidth
              InputProps={
                {
                  placeholder: 'e.g. 7-6, 6-2'
                }
              }
              sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%': '100%'}}
              required 
            /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <MobileDateTimePicker
                  label='Tournament Date and Time'
                  defaultValue={dayjs()}
                  sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                  onChange={handleStartDateTimeChange}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <MobileDateTimePicker
                  label='Last date to register'
                  defaultValue={dayjs()}
                  sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                  onChange={handleLastDateToRegisterChange}
                />
              </LocalizationProvider>
            </Stack>

            <Stack
              direction={!isDesktop ? 'column' : 'row'}
              alignItems={!isDesktop ? 'stretch' : 'center'}
              justifyContent="space-between"
              mb={1}
              spacing={!isDesktop ? 0 : 2}
            >

              <TextField
                label="Entry Fee"
                value={fee}
                onChange={handleFeeChange}
                sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                fullWidth
                inputProps={{ min: 0 }}
                required
              />

              <TextField
                label="Type"
                value={tournamentType}
                fullWidth
                InputProps={{
                  placeholder: 'Open/Divisional/Flex league etc.'
                }}
                onChange={handleTournamentTypeChange}
                inputProps={{ min: 0, max: 59 }}
                sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                required
              />
              <Autocomplete
                disablePortal
                id="tournament-location"
                options={allCourtsData}
                groupBy={(option) => `${option.Area || ''}, ${option.City || ''}`}
                getOptionLabel={(option) => option['Location Name']}
                onChange={handleTournamentLocationChange}
                fullWidth
                sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                renderInput={(params) => <TextField {...params} label="Location" />}
                required
              />

            </Stack>
            <Stack
              direction={!isDesktop ? 'column' : 'row'}
              alignItems={!isDesktop ? 'stretch' : 'center'}
              justifyContent="space-between"
              mb={1}
              spacing={!isDesktop ? 0 : 2}
            >
              {/* <Autocomplete
                multiple
                id="tags"
                options={drillOptions}
                getOptionLabel={(option) => option.label}
                value={tags}
                onChange={handleTagsChange}
                sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '100%' : '100%' }}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderOption={(props, option) => (
                    <li {...props}>
                    <div>
                        <strong>{option.label}</strong>
                        <div>{option.description}</div>
                    </div>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Drills" placeholder="Add tags" fullWidth />
                )}
                required
                /> */}

            </Stack>

            <TextField
              label="Miscellaneous text"
              value={notes}
              onChange={handleNotesChange}
              fullWidth
              InputProps={{
                placeholder: 'Age/Rating restrictions, Payment method etc.'
              }}
              sx={{ marginTop: 1, marginBottom: 1 }}
              multiline
              rows={4}
            />

            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
              Submit
            </Button>
          </form>
        </Box>
      </Container>
    </>
  );
}