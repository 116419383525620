import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Container,
  Typography,
  Stack,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  CircularProgress,
  Checkbox,
  Autocomplete,
  createFilterOptions
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import ReactGA from 'react-ga';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { AuthContext } from '../utils/auth';
import useResponsive from '../hooks/useResponsive';
import { getUserByUserId } from "../api/ApiGateway"
import allCourtsData from '../utils/tln_allcourts.json'
import { getUserByEmailOrPhone } from '../utils/getUser';

const filter = createFilterOptions();

function getTennisMatchResult(score) {
  const sets = score.split(',');

  let player1SetsWon = 0;
  let player2SetsWon = 0;

  sets.forEach(set => {
    const [gamesPlayer1, gamesPlayer2] = set.split('-').map(Number);

    if (gamesPlayer1 > gamesPlayer2) {
      player1SetsWon += 1;
    } else if (gamesPlayer2 > gamesPlayer1) {
      player2SetsWon += 1;
    }
    // If games are equal, no winner for the set
  });

  if (player1SetsWon > player2SetsWon) {
    return {
      winner: 0, // Player 1 wins
      score
    };
  }

  if (player2SetsWon > player1SetsWon) {
    // Player 2 wins, flip the score
    const flippedScore = sets.map(set => {
      const [gamesPlayer1, gamesPlayer2] = set.split('-').map(Number);
      return `${gamesPlayer2}-${gamesPlayer1}`;
    }).reverse().join(',');

    return {
      winner: 1, // Player 2 wins
      score: flippedScore
    };
  }

  // Draw, both players won the same number of sets
  return {
    winner: -1,
    score
  };
}

export default function NewSessionPage(props) {
  document.title = 'New Session | Playgrade';
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });
  const isDesktop = useResponsive('up', 'lg');
  const [opponent, setOpponent] = useState('');
  const [player, setPlayer] = useState('');
  const [score, setScore] = useState('');
  const [durationHours, setDurationHours] = useState('');
  const [durationMinutes, setDurationMinutes] = useState('');
  const [courtLocation, setCourtLocation] = useState({});
  const [datetime, setDateTime] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [notes, setNotes] = useState('');
  const [matchType, setMatchType] = useState(props.type || 'Competitive');
  const [tags, setTags] = useState([]);
  const [newOpponent, setNewOpponent] = useState(false)
  const [newPlayer, setNewPlayer] = useState(false)
  const [loggedInUserId, setLoggedInUserId] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});


  const handleOpponentChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setOpponent({
        id: newValue,
        displayName: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      setOpponent({
        id: uuidv4(),
        displayName: newValue.inputValue,
      });
      setNewOpponent(true)
    } else {
      setOpponent(newValue);
    }
  };

  const handlePlayerChange = (event, newValue) => {
    console.log(newValue);
    if (typeof newValue === 'string') {
      setPlayer({
        id: newValue,
        displayName: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      setPlayer({
        id: uuidv4(),
        displayName: newValue.inputValue,
      });
      setNewPlayer(true);
    } else {
      setPlayer(newValue);
    }
  };

  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };

  const handleDurationHoursChange = (event) => {
    setDurationHours(event.target.value);
  };

  const handleDurationMinutesChange = (event) => {
    setDurationMinutes(event.target.value);
  };

  const handleCourtLocationChange = (event, value) => {
    setCourtLocation(value);
  };

  const handleDateChange = (newDate) => {
    setDateTime(newDate);
  };

  const handleTimeChange = (newTime) => {
    setTime(newTime);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleTagsChange = (event, value) => {
    setTags(value);
  };

  const { currentUser } = useContext(AuthContext);
  const userEmail = currentUser.email;
  const userName = currentUser.displayName;
  const [allCourtLocations, setallCourtLocations] = useState([]);
  const [allUsers, setallUsers] = useState([])
  const [isCoachMode, setIsCoachMode] = useState(false);

  const handleCoachModeToggle = () => {
    setIsCoachMode(!isCoachMode);
  };

  useEffect(() => {
    console.log('courtLocation', courtLocation)
  }, [courtLocation]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = await getUserByEmailOrPhone(currentUser);
        const userData = await getUserByUserId(userId);
        setUser(userData.data);
        setLoggedInUserId(userId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function immediately

  }, [currentUser, loggedInUserId]);

  useEffect(() => {
    if (!isCoachMode) {
      // Set the default player value to { id: loggedInUserId, displayName: userName }
      setPlayer({
        id: loggedInUserId,
        displayName: user.displayName,
      });
    }
    else {
      setPlayer('');
    }
  }, [isCoachMode, loggedInUserId, user]);

  useEffect(() => {
    let cancelRequest = null;

    const getData = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-API-Key': '2LnZuXL3eoai8wCLbaQ8O67ZEghqX5C07e2uSMQE'
        };

        // Create a cancel token
        const source = axios.CancelToken.source();

        // Assign the cancel token to the cancelRequest variable
        cancelRequest = source;

        const courtResponse = await axios.get(`https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/courts`, {
          cancelToken: source.token,
        });

        setallCourtLocations(courtResponse.data);
        const userResponse = await axios.get(`https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/users`, {
          cancelToken: source.token,
        });

        setallUsers(userResponse.data);
        setIsLoading(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled, do nothing.
        } else {
          console.log(error);
          setIsLoading(false);
        }
      }
    };

    // Call the async function inside the effect.
    getData();

    // Clean up function: Cancel the request when the component unmounts.
    return () => {
      if (cancelRequest) {
        cancelRequest.cancel('Request canceled due to component unmount.');
      }
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newSessionData = {
      id: uuidv4(),
      createdByEmail: userEmail,
      matchType,
      createdByName: userName,
      createdByUstaRating: '',
      createdByUtrRating: '',
      opponent: opponent.displayName,
      score,
      durationHours,
      durationMinutes,
      courtLocation: courtLocation['Location Name'],
      courtName: courtLocation['Location Name'],// ['Location Name'], Need to change this after locationV2
      courtId: courtLocation.id,
      notes,
      tags,
      datetime,
      opponentName: opponent.displayName,
      opponentId: opponent.id,
      opponentUstaRating: opponent?.ustaRating || '',
      opponentUtrRating: opponent?.utrRating || '',
    };

    const newSessionDataV2 = {
      id: uuidv4(),
      coaches: [],
      players: [],
      createdBy: [
        {
          id: loggedInUserId,
          email: userEmail,
          name: userName,
          utrRating: '',
          ustaRating: '',
        },
      ],
      matchType,
      score,
      durationHours,
      durationMinutes,
      courtLocation: courtLocation['Location Name'],
      courtName: courtLocation['Location Name'],
      courtId: courtLocation.id,
      notes,
      tags,
      datetime,
    };
    const sessionResult = getTennisMatchResult(score)
    const winnerFromScore = sessionResult.winner;
    newSessionDataV2.score = sessionResult.score;

    if (!isCoachMode) {
      newSessionDataV2.players = [
        {
          id: loggedInUserId,
          name: userName,
          type: winnerFromScore === 0 ? 'winner' : 'opponent',
          utrRating: '',
          ustaRating: '',
        },
        {
          id: opponent.id,
          name: opponent.displayName,
          type: winnerFromScore === 1 ? 'winner' : 'opponent',
          utrRating: opponent?.utrRating || '',
          ustaRating: opponent?.ustaRating || '',
        },
      ];
    }
    else {
      newSessionDataV2.players = [
        {
          id: player.id,
          name: player.displayName,
          type: winnerFromScore === 0 ? 'winner' : 'opponent',
          utrRating: '',
          ustaRating: '',
        },
        {
          id: opponent.id,
          name: opponent.displayName,
          type: winnerFromScore === 1 ? 'winner' : 'opponent',
          utrRating: opponent?.utrRating || '',
          ustaRating: opponent?.ustaRating || '',
        },
      ];
      newSessionDataV2.coaches = [
        {
          id: loggedInUserId,
          name: userName,
        }
      ];
    }
    console.log(newSessionDataV2);

    const newSessionConfig = {
      method: "post",
      url: "https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/plays",
      headers: {
        "Content-Type": "text/plain",
      },
      data: newSessionDataV2,
    };

    const newUserConfig = {
      method: "post",
      url: "https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/users",
      headers: {
        "Content-Type": "text/plain",
      },
      data: {
        ...opponent,
      },
    }

    try {
      const createdByUserId = await getUserByEmailOrPhone(currentUser);
      const createdByUser = await getUserByUserId(createdByUserId);
      newSessionData.createdByUserId = createdByUserId
      newSessionData.createdByUstaRating = createdByUser.data[0]?.ustaRating || '';
      newSessionData.createdByUtrRating = createdByUser.data[0]?.utrRating || '';
      await axios(newSessionConfig);
      newUserConfig.data.createdByUserId = createdByUserId
      if (newOpponent) {
        await axios(newUserConfig);
      }
    } catch (error) {
      console.log(error);
    }

    window.location.href = "/v2/sessions";
  };

  return (
    <>
      <Helmet>
        <title>New Session | Playgrade</title>
      </Helmet>

      <Container>
        {isLoading ? (
          // Render loading indicator
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 'calc(100vh - 64px)', // Adjust this value based on your layout
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Stack
              direction={!isDesktop ? 'column' : 'row'}
              alignItems={!isDesktop ? 'stretch' : 'center'}
              justifyContent="space-between"
              mb={5}
              spacing={!isDesktop ? 2 : 0}
            >
              <Typography variant="h4" gutterBottom>
                {props.type || 'Competitive'} Match
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCoachMode}
                    onChange={handleCoachModeToggle}
                    color="primary"
                  />
                }
                label="I am a coach"
              />
              {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Session
          </Button> */}
            </Stack>

            <Box sx={{ p: 2 }}>
              <form onSubmit={handleSubmit}>
                <Stack
                  direction={!isDesktop ? 'column' : 'row'}
                  alignItems={!isDesktop ? 'stretch' : 'center'}
                  justifyContent="space-between"
                  mb={1}
                  spacing={!isDesktop ? 0 : 2}
                >
                  <Autocomplete
                    value={player}
                    onChange={handlePlayerChange}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;

                      const isExisting = options.some(
                        (option) =>
                          inputValue === option.displayName ||
                          inputValue === option.inputValue
                      );

                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          id: inputValue,
                          displayName: `+ ${inputValue}`,
                          inputValue,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-player"
                    options={allUsers}
                    disabled={!isCoachMode}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      return option.displayName;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.displayName}</li>}
                    sx={{ width: isDesktop ? '50%' : '100%' }}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Player" required />}
                  />

                  <Autocomplete
                    value={opponent}
                    onChange={handleOpponentChange}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;

                      const isExisting = options.some(
                        (option) =>
                          inputValue === option.displayName ||
                          inputValue === option.inputValue
                      );

                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          id: inputValue,
                          displayName: `+ ${inputValue}`,
                          inputValue,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-opponent"
                    options={allUsers}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      return option.displayName;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.displayName}</li>}
                    sx={{ width: isDesktop ? '50%' : '100%' }}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Opponent" required />}
                  />
                  <TextField
                    label="Score"
                    value={score}
                    onChange={handleScoreChange}
                    fullWidth
                    InputProps={
                      {
                        placeholder: 'e.g. 7-6, 6-2'
                      }
                    }
                    sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                    required
                  />
                </Stack>

                <Stack
                  direction={!isDesktop ? 'column' : 'row'}
                  alignItems={!isDesktop ? 'stretch' : 'center'}
                  justifyContent="space-between"
                  mb={1}
                  spacing={!isDesktop ? 0 : 2}
                >

                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <MobileDateTimePicker
                      label='Date and Time'
                      defaultValue={dayjs()}
                      sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                      onChange={handleDateChange}
                    />
                  </LocalizationProvider>
                  <TextField
                    label="Duration (Hours)"
                    value={durationHours}
                    onChange={handleDurationHoursChange}
                    type="number"
                    sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                    fullWidth
                    inputProps={{ min: 0 }}
                    required
                  />

                  <TextField
                    label="Duration (Minutes)"
                    value={durationMinutes}
                    fullWidth
                    onChange={handleDurationMinutesChange}
                    type="number"
                    inputProps={{ min: 0, max: 59 }}
                    sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                    required
                  />

                </Stack>
                <Stack
                  direction={!isDesktop ? 'column' : 'row'}
                  alignItems={!isDesktop ? 'stretch' : 'center'}
                  justifyContent="space-between"
                  mb={1}
                  spacing={!isDesktop ? 0 : 2}
                >
                  <Autocomplete
                    multiple
                    id="tags"
                    options={["UTR", "USTA Tournament", "USTA League", "TLN"]}
                    value={tags}
                    onChange={handleTagsChange}
                    sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                    required
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tags"
                        placeholder="Add tags"
                        fullWidth
                      />
                    )}
                  />

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allCourtsData}
                    groupBy={(option) => `${option.Area || ''}, ${option.City || ''}`}
                    getOptionLabel={(option) => option['Location Name']}
                    onChange={handleCourtLocationChange}
                    fullWidth
                    sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                    renderInput={(params) => <TextField {...params} label="Court" />}
                    required
                  />
                </Stack>

                <TextField
                  label="Notes(visible to all players)"
                  value={notes}
                  onChange={handleNotesChange}
                  fullWidth
                  InputProps={{
                    placeholder: 'e.g. Played well, but need to work on my serve'
                  }}
                  sx={{ marginTop: 1, marginBottom: 1 }}
                  multiline
                  rows={4}
                />

                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  Submit
                </Button>
              </form>
            </Box>
          </>)}
      </Container>
    </>
  );
}