import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  Box,
  Chip,
  Container,
  CircularProgress,
  Typography,
  Stack,
  Button,
  TextField,
  Tab,
  Tabs,
  MenuItem,
  Menu,
  IconButton
} from "@mui/material";
import { getAuth } from "firebase/auth";
import { AuthContext } from '../utils/auth';
import { getFeedbackByFeedbackId, getPlayByPlayId, getUserByUserId, postFeedback, getFeedbacksByPlayId } from "../api/ApiGateway";
import useResponsive from "../hooks/useResponsive";
import Iconify from '../components/iconify';
import { getUserByEmailOrPhone } from "../utils/getUser";

export const SelfFeedbackForm = (params) => {
  document.title = 'Feedback | Playgrade'
  const auth = getAuth()
  const { playId, feedbackId } = useParams();
  const [isSelfFeedbackViewing, setIsSelfFeedbackViewing] = useState(false)
  const [play, setPlay] = useState({})
  const [userId, setUserId] = useState('')
  const { currentUser } = useContext(AuthContext);
  const [stats, setStats] = useState([])
  const isDesktop = useResponsive('up', 'lg');
  const [user, setUser] = useState({})
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const defaultOptions = ["Limited", "Developing", "Average", "Proficient", "Outstanding"];
  const [abstractFeedbacksData, setAbstractFeedbacksData] = useState([]);
  const [isShowOpponentReceived, setIsShowOpponentReceived] = useState(false)
  const [tabIndex, setTabIndex] = useState(0); // state to keep track of the selected tab
  const [playOpponentId, setPlayOpponentId] = useState('')
  const [playCreatedId, setPlayCreatedId] = useState('')
  const [selectedFeedbackId, setSelectedFeedbackId] = useState('')
  const [selectedFeedbackDisabled, setSelectedFeedbackDisabled] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [feedbacksFromAPICall, setFeedbacksFromAPICall] = useState([])

  useEffect(() => {
    if (selectedFeedbackId) {
      const numberOfFilteredFeedbacks = feedbacksFromAPICall.filter(item => item.matchFeedbackId === selectedFeedbackId).length
      setSelectedFeedbackDisabled(numberOfFilteredFeedbacks)
    }
  }, [selectedFeedbackId, feedbacksFromAPICall])

  const copyFeedbackLink = () => {
    const opponentFeedbackLink = `${window.location.host}/v2/feedback/${playId}`;
    navigator.clipboard.writeText(opponentFeedbackLink);
  }

  const handleFeedbackMenuItemClick = (option) => {
    setSelectedFeedbackId(option);
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log('selectedFeedbackId', selectedFeedbackId)
    console.log('filtered', abstractFeedbacksData.filter(item => item.matchFeedbackId === selectedFeedbackId))
  }, [selectedFeedbackId])

  const renderFeedbackMenu = (option, label, feedbackTransactionType, feedbackTransactionTypeColor, feedbackNatureType, feedbackNatureTypeColor = 'info', disabled = false) => (
    <MenuItem disabled={disabled} onClick={() => handleFeedbackMenuItemClick(option)}>
      <Chip label={feedbackTransactionType} color={feedbackTransactionTypeColor} size="small" sx={{ mr: 4 }} />
      {/* {feedbackNatureType!=='' && <Chip label={feedbackNatureType} color={feedbackNatureTypeColor} size="small" sx={{ mr: 4 }} />} */}
      <Typography sx={{ flexGrow: 1, textAlign: 'right', lineHeight: 2 }}>{label}</Typography>
    </MenuItem>
  );

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      const playResult = (await getPlayByPlayId(playId)).data;
      const playerFeedbacks = [];

      // Get all combinations of two players from playResult.players
      playResult.players.forEach((player) => {
        playResult.players.forEach((opponent) => {
          if (player !== opponent) {
            playerFeedbacks.push({
              createdBy: { userId: player.id, name: player.name },
              createdFor: { userId: opponent.id, name: opponent.name },
              feedbackType: "peer",
              play: { id: playId },
              id: uuidv4(),
              matchFeedbackId: `${player.id}_${opponent.id}`
            });
          }
          if (player === opponent) {
            playerFeedbacks.push({
              createdBy: { userId: player.id, name: player.name },
              createdFor: { userId: opponent.id, name: opponent.name },
              feedbackType: "self",
              play: { id: playId },
              id: uuidv4(),
              matchFeedbackId: `${player.id}_${opponent.id}`
            });
          }
        });
      });
      console.log('Player feedbacks', playerFeedbacks)

      playResult.coaches.forEach((coach) => {
        playResult.players.forEach((player) => {
          playerFeedbacks.push({
            createdFor: { userId: coach.id, name: coach.name },
            createdBy: { userId: player.id, name: player.name },
            feedbackType: "coach",
            play: { id: playId },
            id: uuidv4(),
            matchFeedbackId: `${player.id}_${coach.id}`
          });
          playerFeedbacks.push({
            createdBy: { userId: coach.id, name: coach.name },
            createdFor: { userId: player.id, name: player.name },
            feedbackType: "coach",
            play: { id: playId },
            id: uuidv4(),
            matchFeedbackId: `${coach.id}_${player.id}`
          });
        });
      });

      const filteredPlayerFeedbacks = playerFeedbacks.filter((feedback) => {
        return feedback.createdBy.userId === userId || feedback.createdFor.userId === userId;
      });

      filteredPlayerFeedbacks.forEach((item) => {
        item.feedback = [
          {
            name: "Forehand",
            key: "forehand",
            role: "General proficiency: consistency, pace, placement etc.",
            value: "Average",
            options: defaultOptions,
          },
          {
            name: "Backhand",
            key: "backhand",
            role: "General proficiency: consistency, pace, placement etc.",
            value: "Average",
            options: defaultOptions,
          },
          {
            name: "Volley",
            key: "volley",
            role: "General proficiency: consistency, pace, placement etc.",
            value: "Average",
            options: defaultOptions,
          },
          {
            name: "Slice",
            key: "slice",
            role: "General proficiency: consistency, pace, placement etc.",
            value: "Average",
            options: defaultOptions,
          },
          {
            name: "Serve",
            key: "serve",
            role: "Placement, Speed, dependability etc.",
            value: "Average",
            options: defaultOptions,
          },
          {
            name: "Return",
            key: "return",
            role: "Return of serve: consistency, pace, placement etc.",
            value: "Average",
            options: defaultOptions,
          },
          {
            name: "Court Coverage",
            key: "courtCoverage",
            role: "How well does the player cover the court?",
            value: "Average",
            options: defaultOptions,
          },
          {
            name: "Stamina",
            key: "stamina",
            role: "Were you able to tire them out?",
            value: "Average",
            options: defaultOptions,
          },
          {
            name: "Overall Strategy",
            key: "strategy",
            role: "General proficiency: consistency, pace, placement etc.",
            value: "Aggressive",
            options: ["Aggressive", "Defensive"],
          },
        ];
      });
      setAbstractFeedbacksData(filteredPlayerFeedbacks)
      setSelectedFeedbackId(filteredPlayerFeedbacks[0].matchFeedbackId)
    }
    fetchData();
  }, [userId])

  useEffect(() => {
    if (auth.currentUser) {
      setIsUserLoggedIn(true)
    }
  }, [auth.currentUser])

  useEffect(() => {
    if (play) {
      const playOpponentId = play?.players?.filter(player => player.id !== userId)?.map(player => player.id)[0];
      setPlayOpponentId(playOpponentId)
    }
    if (play.createdBy) {
      const playCreatedId = play?.createdBy[0]?.userId || null;
      setPlayCreatedId(playCreatedId)
    }
  }, [play]);
  useEffect(() => {
    feedbacksFromAPICall.forEach((rowItem) => {
      console.log('abstract feedbacks inside coach type', abstractFeedbacksData)
      const filteredFeedback = abstractFeedbacksData.filter(feedback => feedback.matchFeedbackId === rowItem.matchFeedbackId)
      console.log('rowItem', rowItem.matchFeedbackId, filteredFeedback)
      if (filteredFeedback.length > 0) {
        Object.keys(rowItem.feedback).forEach((key) => {
          filteredFeedback[0].feedback.forEach((formItem) => {
            if (key === formItem.key) {
              // console.log('internal debug', formItem.key, key, formItem.value, filteredFeedback[0].feedback[key])
              formItem.value = rowItem.feedback[key]
            }
          });
        });
      }
    });
  }, [feedbacksFromAPICall, abstractFeedbacksData])

  useEffect(() => {
    const fetchData = async () => {
      // let feedbackPlayId = ''
      try {
        setIsLoading(true)
        if (currentUser) {
          const userId = await getUserByEmailOrPhone(currentUser);
          setUserId(userId);
          const userData = await getUserByUserId(userId);
          setUser(userData);
        }

        if (playId) {
          const feedbacksFromPlayId = (await getFeedbacksByPlayId(playId)).data
          setFeedbacksFromAPICall(feedbacksFromPlayId)
        }

        const playdIdReduced = playId
        const playResult = (await getPlayByPlayId(playdIdReduced)).data;
        setPlay(playResult);
        const statsData = await deriveStatsFromPlay(playResult, userId);
        setStats(statsData);
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false)
      }
    };

    fetchData(); // Call the async function immediately
    console.log('showing received: ', isShowOpponentReceived)

    // Ensure to specify the dependencies properly.
  }, [currentUser, isSelfFeedbackViewing, playId, userId, isShowOpponentReceived, playOpponentId]);

  const abstractFeedbackHandleSubmit = (event) => {
    console.log('abstractFeedbackHandleSubmit event', event)
    event.preventDefault();
    const feedbackDataConsumable = {};
    const selectedFeedback = abstractFeedbacksData.find(feedback => feedback.matchFeedbackId === selectedFeedbackId)
    console.log('selectedFeedback', selectedFeedback, selectedFeedback.feedback)
    selectedFeedback.feedback.forEach((item) => {
      feedbackDataConsumable[item.key] = item.value;
    });
    selectedFeedback.feedback = feedbackDataConsumable
    console.log('selectedFeedback', selectedFeedback)
    postFeedback(selectedFeedback)
      .then(() => {
        window.location.reload();
      });
  }
  useEffect(() => {
    console.log('abstractFeedbacksData', abstractFeedbacksData);
  }, [abstractFeedbacksData]);

  const abstractFeedbackHandleChange = (name, value, matchFeedbackId) => {
    setAbstractFeedbacksData((prevFormData) =>
      prevFormData.map((item) => {
        if (item.matchFeedbackId === selectedFeedbackId) {
          // Update the feedback array
          const updatedFeedback = item.feedback.map((feedbackItem) => {
            if (feedbackItem.name === name) {
              return {
                ...feedbackItem,
                value,
              };
            }
            return feedbackItem;
          });
          console.log('updatedFeedback', updatedFeedback);
          return {
            ...item,
            feedback: updatedFeedback,
          };
        }
        return item;
      })
    );
    // console.log('abstractFeedbacksData', abstractFeedbacksData)
  };

  return (
    <Container>
      {/* Tabs for different sections */}
      {isLoading ? (
        // Render loading indicator
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 'calc(100vh - 64px)', // Adjust this value based on your layout
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        (
          <>
            <Stack
              direction={!isDesktop ? 'column' : 'row'}
              alignItems={!isDesktop ? 'stretch' : 'center'}
              justifyContent="space-between"
              mt={5}
              mb={2}
              spacing={!isDesktop ? 2 : 0}
            >
              {stats.map((item) => (
                <Box key={item.name} boxShadow={1} p={2}>
                  <Typography variant="body1">{item.name}</Typography>
                  <Typography variant="h6">{item.stat}</Typography>
                </Box>
              ))}
            </Stack>
            <Button
              id="new-session-button"
              variant="contained"
              onClick={handleMenuOpen}
            >
              Select Feedback
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'new-session-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{ mt: 1 }}
              getContentAnchorEl={null} // Prevents the menu from covering the button
            >
              {abstractFeedbacksData.map((feedback) => {
                const isCreatedByUser = feedback.createdBy.userId === userId;
                const playerName = isCreatedByUser ? feedback.createdFor.name : feedback.createdBy.name;
                const feedbackTransactionType = feedback.feedbackType === 'self' ? 'Self' : (isCreatedByUser ? 'Given' : 'Received');
                const feedbackTransactionTypeColor = feedbackTransactionType === 'Self' ? 'success' : (isCreatedByUser ? 'primary' : 'error');
                const feedbackNatureType = feedback.feedbackType === 'coach' ? 'Coach' : (feedback.feedbackType === 'peer' ? 'Peer' : '');
                return renderFeedbackMenu(feedback.matchFeedbackId, playerName, feedbackTransactionType, feedbackTransactionTypeColor, feedbackNatureType);
              })}
            </Menu>
            <form onSubmit={abstractFeedbackHandleSubmit}>
              <br />
              <Typography variant="body1">
                Provide feedback to:
                {abstractFeedbacksData
                  .filter((item) => item.matchFeedbackId === selectedFeedbackId)
                  .map((item) => (
                    <span key={item.id}>
                      <strong>{item.createdFor.name}</strong>
                    </span>
                  ))}
              </Typography>
              <Stack direction="column" alignItems="center" justifyContent="space-between" mt={5} spacing={2}>
                {abstractFeedbacksData
                  .filter((item) => item.matchFeedbackId === selectedFeedbackId)
                  .map((item) => item.feedback.map((attribute) => (
                    <Box
                      key={attribute.name}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        border: "1px solid",
                        borderColor: "grey.300",
                        borderRadius: "4px",
                        width: isDesktop ? "700px" : "300px",
                        p: 2,
                        "&:hover": {
                          borderColor: "grey.400",
                        },
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold" color="text.primary" marginRight={2}>
                        {attribute.name}
                      </Typography>
                      <div>
                        {attribute.options.map((option, index) => (
                          <Button
                            key={option}
                            variant="outlined"
                            size="small"
                            sx={{
                              mr: 1,
                              mt: 1,
                              borderRadius: 0,
                              ":hover": {
                                backgroundColor: "#2065D1",
                                color: "white",
                              },
                              backgroundColor: attribute.value === option ? "#2065D1" : "white",
                              color: attribute.value === option ? "white" : "text.primary",
                              "&:first-child": {
                                borderTopLeftRadius: "4px",
                                borderBottomLeftRadius: "4px",
                              },
                              "&:last-child": {
                                borderTopRightRadius: "4px",
                                borderBottomRightRadius: "4px",
                              },
                            }}
                            onClick={() => abstractFeedbackHandleChange(attribute.name, option)}
                            disabled={selectedFeedbackDisabled}
                          >
                            {option}
                          </Button>
                        ))}
                      </div>
                    </Box>
                  )))}
              </Stack>

              {!selectedFeedbackDisabled && (
                <Stack direction="row" alignItems="center" justifyContent="center" mt={6}>
                  <Button type="submit" variant="contained" size="large">
                    Submit
                  </Button>
                </Stack>
              )}
            </form>

          </>
        )
      )}
    </Container>
  );

}

const deriveStatsFromPlay = async (play, userId) => {
  const { createdByUserId, opponentId, coachUserId } = play;
  console.log(play)
  const result = {}
  const stats = []
  if (play.players) {
    const playerTypeDict = {};

    // Create an array of promises for each player
    const playerPromises = play.players.map(async (player) => {
      const { type, id } = player;
      const playerName = (await getUserByUserId(id)).data.displayName;

      if (!playerTypeDict[type]) {
        playerTypeDict[type] = [];
      }

      playerTypeDict[type].push(playerName);
    });

    // Wait for all promises to resolve
    await Promise.all(playerPromises);
    console.log('playerDict', playerTypeDict)

    // Process the dictionary and push stats
    Object.entries(playerTypeDict).forEach(([playerType, playerNames]) => {
      const formattedPlayerType = playerType.charAt(0).toUpperCase() + playerType.slice(1);
      const playersList = playerNames.join(', ');

      stats.push({
        name: formattedPlayerType,
        stat: playersList,
      });
    });
  }

  if (play.score) {
    stats.push({
      name: 'Score',
      stat: play.score
    })
  }
  if (play.datetime) {
    stats.push({
      name: 'Date',
      stat: new Date(play.datetime).toLocaleDateString()
    })
  }
  if (play.durationHours && play.durationMinutes) {
    stats.push({
      name: 'Duration',
      stat: `${play.durationHours} hrs ${play.durationMinutes} min`
    })
  }
  if (play.courtLocation) {
    stats.push(({
      name: 'Location',
      stat: play.courtLocation
    }))
  }
  if (play.coaches.length > 0) {
    const coachNames = play.coaches.map(coach => coach.name).join(', ');

    stats.push({
      name: "Coach",
      stat: coachNames,
    });
  }
  if (play.drills && play.drills.length > 0) {
    const drillNames = play.drills.map(drill => drill.label).join(', ');

    stats.push({
      name: "Drills",
      stat: drillNames,
    });
  }
  console.log('all stats:', stats)

  return stats
}

export const invertScore = (score) => {
  if (!score) {
    return "";
  }
  const sets = score.split(",");
  const invertedSets = sets.map((set) => {
    const setScore = set.split("(");
    if (setScore.length === 1) {
      return setScore[0].split("-").reverse().join("-");
    }
    if (setScore.length === 2) {
      const invertedGames = setScore[0].trim().split("-").reverse().join("-");
      const invertedTieBreak = setScore[1].replace(")", "").trim().split("-").reverse().join("-");
      console.log(invertedGames, invertedTieBreak)
      return `${invertedGames}(${invertedTieBreak})`;
    }
    return ''
  });
  return invertedSets.join(",");
};
