// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/v2/dashboard',
    icon: icon('ic_dashboard'),
    comingSoon: true,
  },
  {
    title: 'Sessions',
    path: '/v2/sessions',
    icon: icon('ic_sessions'),
    comingSoon: true,
  },
  {
    title: 'Journal',
    path: '/v2/journal',
    icon: icon('ic_journal'),
    comingSoon: true,
  },
  {
    title: 'Court Radar',
    path: '/v2/courts',
    icon: icon('ic_tenniscourt'),
    comingSoon: true,
  },
  {
    title: 'Tournaments',
    path: '/v2/tournaments',
    icon: icon('ic_tournament'),
    comingSoon: false,
  },

];

export default navConfig;
