import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from 'uuid';
import Collapse from '@mui/material/Collapse';
import ReactGA from 'react-ga';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    CircularProgress,
    TableRow,
    MenuItem,
    Menu,
    Grid,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Chip,
    Autocomplete,
    TextField,
    Tab
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState, useContext } from 'react';
import {
    AppWidgetSummary,

} from '../sections/@dashboard/app';
import useResponsive from '../hooks/useResponsive';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { AuthContext } from '../utils/auth';
import { getTournamentById, getJournalentriesByUserId, createTournamentRegistrations, getUserByUserId, updateTournamentRegistrations } from "../api/ApiGateway";
import { getUserByEmailOrPhone } from '../utils/getUser';

const categories = [
    {
        name: 'U-10 Mixed (Round Robin format)',
        type: 'Round-robin',
        fee: 'INR 800'
    },
    {
        name: 'U-12 Boys & Girls (Round Robin format)',
        type: 'Round-robin',
        fee: 'INR 800'
    },
    {
        name: 'U-14 Boys & Girls (Round Robin format)',
        type: 'Round-robin',
        fee: 'INR 800'
    },
    {
        name: 'U-16 Boys & Girls (Round Robin format)',
        type: 'Round-robin',
        fee: 'INR 800'
    }
]

function capitalizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user['Location Name'].toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export function formatDate(date) {
    const options = { day: 'numeric', month: 'short' };
    const formattedDate = new Date(date).toLocaleDateString('en-US', options);
    return formattedDate;
}

function formatTime(time) {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = new Date(time).toLocaleTimeString('en-US', options);
    return formattedTime;
}

export default function JournalEntriesForUser() {
    const [open, setOpen] = useState(null);
    const [tournamentMeta, setTournamentMeta] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { tournamentId } = useParams();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [user, setUser] = useState({});
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const isDesktop = useResponsive('up', 'lg');
    const [USERLIST, setUSERLIST] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const [loggedInUserId, setLoggedInUserId] = useState('');
    const [isLoggedInUserHost, setIsLoggedInUserHost] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoggedInUserRegistered, setIsLoggedInUserRegistered] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [tableHead, setTableHead] = useState([
        { id: 'players', label: 'Players', alignRight: false },
        { id: 'opponents', label: 'Opponents', alignRight: false },
        { id: 'courtType', label: 'Surface', alignRight: false },
        { id: 'duration', label: 'Duration', alignRight: false },
        { id: 'datetime', label: 'Date', alignRight: false },
        { id: 'focusAreas', label: 'Focus Areas', alignRight: false },
        { id: 'actions', label: 'Actions', alignRight: false },
    ])
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        setRowsPerPage(isDesktop ? 5 : 10);
    }, [isDesktop]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property === 'courtName' ? 'locationName' : property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.opponent);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setAnchorEl(null);
        if (option === 'competitiveMatch') {
            window.location.href = '/v2/journal/create';
        } else if (option === 'practiceMatch') {
            window.location.href = '/v2/journal/create/practice';
        } else if (option === 'drills') {
            window.location.href = '/v2/journal/create/training';
        }
    };

    const renderMenuItem = (option, label, tagLabel, tagColor, disabled = false) => (
        <MenuItem disabled={disabled} onClick={() => handleOptionClick(option)}>
            <Chip label={tagLabel} color={tagColor} size="small" sx={{ mr: 4 }} />
            <Typography sx={{ flexGrow: 1, textAlign: 'right', lineHeight: 2 }}>{label}</Typography>
        </MenuItem>
    );


    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length) : 0;

    const isNotFound = !filteredUsers.length && !!filterName;

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const tournamentMetaResponse = await getTournamentById(tournamentId);
                // setTournamentMeta(tournamentMetaResponse.data);
                const userId = await getUserByEmailOrPhone(currentUser);
                console.log('userId', userId)
                setLoggedInUserId(userId);
                const userData = await getUserByUserId(userId);
                console.log(userData.data)
                setUser(userData.data);
                const journalEntriesData = await getJournalentriesByUserId(userId);
                // setTournamentRegistrations(journalEntriesData.data);
                console.log('journal entries data', journalEntriesData.data)
                setUSERLIST(journalEntriesData.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [tournamentId, currentUser]);

    useEffect(() => {
        // Ensure tournamentMeta.hosts is defined before accessing it
        if (tournamentMeta.hosts && tournamentMeta.hosts.length > 0) {
            const isHost = tournamentMeta.hosts.some(host => host.id === loggedInUserId);
            if (isHost) {
                setIsLoggedInUserHost(true);
                setTableHead([
                    { id: 'registrationName', label: 'Name', alignRight: false },
                    { id: 'status', label: 'Status', alignRight: false },
                    { id: 'category', label: 'Category', alignRight: false },
                    { id: 'fee', label: 'Fee', alignRight: false },
                    { id: 'approval', label: 'Admin Actions', alignRight: false },
                ]);
            }
        }
    }, [loggedInUserId, tournamentMeta]);

    useEffect(() => {
        if (USERLIST) {
            const isRegistered = USERLIST.some(user => user.userId === loggedInUserId);
            setIsLoggedInUserRegistered(isRegistered);
        }
    }, [USERLIST, loggedInUserId]);


    return (
        <>
            <Helmet>
                <title>Journal| Playgrade</title>
            </Helmet>
            <Container maxWidth="lg">
                {isLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: 'calc(100vh - 64px)', // Adjust this value based on your layout
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                Journal Entries
                            </Typography>

                            {/* New Session button with the menu */}
                            <Button
                                id="new-session-button"
                                variant="contained"
                                onClick={handleMenuOpen}
                            >
                                + New
                            </Button>


                            {/* Menu */}
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                MenuListProps={{
                                    'aria-labelledby': 'new-session-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{ mt: 1 }}
                                getContentAnchorEl={null} // Prevents the menu from covering the button
                            >
                                {renderMenuItem('competitiveMatch', 'Competitive', 'Match', 'primary')}
                                {renderMenuItem('practiceMatch', 'Casual', 'Match', 'primary', true)}
                                {/* {renderMenuItem('coachingSession', 'Guided', 'Training', 'error', true)} */}
                                {renderMenuItem('drills', 'Drills', 'Training', 'error', true)}
                                {/* Add more menu items with tags/chips here */}
                            </Menu>
                        </Stack>
                        <Card>
                            {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <UserListHead
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={tableHead}
                                            rowCount={USERLIST.length}
                                            numSelected={selected.length}
                                            onRequestSort={handleRequestSort}
                                            onSelectAllClick={handleSelectAllClick}
                                            showCheckBox={isLoggedInUserHost}
                                        />
                                        <TableBody>
                                            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                                const { id, courtType, sessionDateTime: datetime, durationHours, durationMinutes } = row;
                                                console.log(row)
                                                return (
                                                    <>
                                                        <TableRow hover key={id} tabIndex={-1}>
                                                            <TableCell align="left">
                                                                {row.players && row.players.length > 0
                                                                    ? row.players.map((player) => player.displayName).join(', ')
                                                                    : ''}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.opponents && row.opponents.length > 0
                                                                    ? row.opponents.map((player) => player.displayName).join(', ')
                                                                    : ''}
                                                            </TableCell>
                                                            <TableCell align="left">{courtType}</TableCell>
                                                            <TableCell align="left">{durationHours} hrs {durationMinutes} mins</TableCell>
                                                            <TableCell align="left">{formatDate(datetime)}</TableCell>
                                                            <TableCell align="left">{row.focusAreas && row.focusAreas.length > 0
                                                                ? row.focusAreas.join(', ')
                                                                : ''}</TableCell>
                                                            <TableCell align="left">
                                                                <Button variant="contained" color="primary" component={Link} to={`/v2/journal/view/${id}`} >View</Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                );
                                            })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>


                                        {isNotFound && (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                        <Paper
                                                            sx={{
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            <Typography variant="h6" paragraph>
                                                                Not found
                                                            </Typography>

                                                            <Typography variant="body2">
                                                                No results found for &nbsp;
                                                                <strong>&quot;{filterName}&quot;</strong>.
                                                                <br /> Try checking for typos or using complete words.
                                                            </Typography>
                                                        </Paper>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredUsers.length}  // Use filteredUsers.length instead of USERLIST.length
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Card>
                    </>
                )}

            </Container></>
    );
}
