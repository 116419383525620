import React, { createContext, useState, useEffect } from 'react';
import auth from './firebase';

// Create the AuthContext
export const AuthContext = createContext();

// Create the AuthContext Provider
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Sign up with email and password
  const signUp = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  // Sign in with email and password
  const signIn = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  // Sign out
  const signOut = () => {
    return auth.signOut();
  };

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Clean up the listener
    return () => unsubscribe();
  }, []);

  // Provide the currentUser and authentication methods to the child components
  const authContextValue = {
    currentUser,
    signUp,
    signIn,
    signOut,
  };

  // Render the AuthContext Provider with the provided child components
  return (
    <AuthContext.Provider value={authContextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
